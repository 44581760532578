import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Sankey's Customer Story - Rotaready"
          description="Here's how the team at Sankey's managed to save themselves almost an entire day every week by ditching their laborious approach to staff scheduling."
          url="customer-stories/hospitality-sankeys"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Chefs with a prepared plate of seafood"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Sankey's logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Here's how the team at Sankey's managed to save themselves almost an entire day every week by ditching their laborious approach to staff scheduling." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="2" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="30" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="517%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="5 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Sankey's" />
              </Content>

              <Content>
                <TextBlock text="Sankey's restaurant has been run by the Sankey family since the 1960's, with third generation Matthew Sankey now at the helm. In 2009 Matthew expanded the Sankey's offering by opening its very first shop with co-owner Graeme Anderson, after the pair met by chance at the world famous Billingsgate Fish Market." />
              </Content>

              <Content>
                <TextBlock text="Having both grown up in the seafood industry and sharing a passion for fish, they wanted to ensure that this passion was always reflected in the level of service every single one of Sankey's customers receives. Part of this was making sure they have the right people on hand to meet demand and that their staff can focus the majority of their time on the customers rather than administrative tasks." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A laborious approach to staff scheduling" />
              </Content>

              <Content>
                <TextBlock text="Initially the business was using spreadsheets and digital calendars to create rotas and manage staff absences. But with this approach it was taking hours to build rotas each week; a poor use of time and resources." />
              </Content>

              <Content>
                <TextBlock text="The business wanted to make better use of its time, so Matthew and Graeme set about finding a solution that could speed up laborious tasks such as building and sharing staff rotas and monitoring staff attendance." />
              </Content>

              <Content>
                <TextBlock text="Sankey's approached Rotaready following a recommendation from another customer. After seeing a demo of the platform, the decision to work with Rotaready was easy for the pair and they set about implementation immediately. Working closely with the team at Rotaready they had both sites live in under two weeks." />
              </Content>

              <Content>
                <Blockquote
                  text="Deciding to work with Rotaready was a really easy decision for us. You get the best of both worlds; a product with a really logical and clear front end, and all the magic happening behind the scenes."
                  attribution="Matthew Sankey - Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Game changing results" />
              </Content>

              <Content>
                <TextBlock text="Sankey's use the majority of the product's features but have found staff templates and cost control particularly useful. Since setting up a series of templates at the very beginning they haven't had to make any changes and are able to build weekly rotas completely automatically. Prior to this, it was taking almost a whole day every week." />
              </Content>

              <Content>
                <TextBlock text="Cost control and sanitisation have also been instrumental in Sankey's budgeting and ensuring staff are being paid accurately, with hours being automatically trimmed by Rotaready based on customised settings and overtime rules." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline.childImageSharp.gatsbyImageData}
                alt="Plate of seafood and bottle of English sparkling wine"
              />
            </Section>

            <Section>
              <Content>
                <TextBlock text="Attendance discrepancies are also a thing of the past thanks to Rotaready. Managers are able to log in to HQ and easily view and resolve any flagged attendance issues with the click of a button. Staff have full visibility of their upcoming shifts via the mobile app so always know when they are working next. And thanks to instant rota validation, there are no longer any issues with regards to staff exceeding contractual hours or break times." />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all of these things have not only saved Sankey's a huge amount of time on admin tasks, and has delivered the business an ROI of 517%." />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="An ongoing partnership" />
              </Content>

              <Content>
                <TextBlock>
                  Going forward Rotaready will continue to work closely with Sankey's to ensure they are getting the most out of all new features and developments. Next on the agenda for Sankey's is enabling <Link style="color:inherit;" to='/integrations'>integrations</Link> between Rotaready and its <Link style="color:inherit;" to='/payroll'>payroll</Link> and EPOS systems, Quickbooks and Goodtill.
                </TextBlock>
              </Content>

              <Content>
                <Blockquote
                  text="Working with Rotaready has been a pleasure. Even as a small business we feel highly valued, the team really take our feedback on board and are always on hand to help us with anything we need. I would recommend Rotaready to anyone looking for an outstanding workforce management partner."
                  attribution="Matthew Sankey - Director"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_sankeys"
                label="rotaready.com/customer-stories/hospitality-sankeys"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Sankeys.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-sankeys.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-sankeys.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline: file(relativePath: { eq: "customer-stories/inline-sankeys.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
